import { template as template_6091d2adb1a444cca1aeda256bba50ee } from "@ember/template-compiler";
const FKControlMenuContainer = template_6091d2adb1a444cca1aeda256bba50ee(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
