import { template as template_420cd3878a644db3ade8bc1d41a6ac7c } from "@ember/template-compiler";
const FKText = template_420cd3878a644db3ade8bc1d41a6ac7c(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
