import { template as template_a5d634ffcf59422d9a0627895a3c3de0 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import icon from "discourse/helpers/d-icon";
export default class FKAlert extends Component {
    get type() {
        return this.args.type || "info";
    }
    static{
        template_a5d634ffcf59422d9a0627895a3c3de0(`
    <div class="form-kit__alert alert alert-{{this.type}}" ...attributes>
      {{#if @icon}}
        {{icon @icon}}
      {{/if}}

      <span class="form-kit__alert-message">{{yield}}</span>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
